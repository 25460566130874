.topheader {
    background-color: #481EAF;

    &>* {
        &>* {
            height: 100%;

            &>* {
                padding-top: 12px;
                padding-bottom: 12px;
                height: 100%;
            }
        }
    }

    .contect {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;

        a {
            color: #fff;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            font-size: 14px;
            text-decoration: none;

            .iconfont {
                font-size: 16px;
            }

            @media screen and (min-width: 0px) and (max-width: 767px) {
                align-items: flex-start;
                margin-right: auto;

            }
        }

        @media screen and (min-width: 0px) and (max-width: 767px) {
            display: block;
        }

        @media screen and (min-width: 0px) and (max-width: 390px) {
            gap: 5px;
        }
    }

    .socialicon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        flex-wrap: wrap;
        margin-left: auto;

        a {
            position: relative;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 5px;
            cursor: pointer;
            overflow: hidden;
            transition: background-color 0.3s ease;
            background-image: linear-gradient(to bottom, #5127B8, #481EAF);
            text-decoration: none;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #5127B8;
                opacity: 0;
                transition: opacity 0.3s ease;
                border-radius: 5px;
                z-index: 1;
            }

            &:hover::before {
                opacity: 1;
            }
        }

        .iconfont {
            font-size: 12px;
            position: relative;
            z-index: 2;
        }

        @media screen and (min-width: 0px) and (max-width: 390px) {
            gap: 5px;
        }

        @media screen and (min-width: 0px) and (max-width: 257px) {
            gap: 5px;
            justify-content: center;
            flex-wrap: nowrap;
        }
    }
}

.navbar {
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: top 0.3s ease, transform 0.3s ease;

    .container {
        flex-wrap: nowrap;
        z-index: 1;
        position: relative;

        .navbar-brand {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .img-fluid {
                transition: 0.3s ease all;
            }
        }

        .navbar-toggler {
            border: none;
            padding: 0;

            .navbar-toggler-icon {
                stroke: #4A22E6;
                width: 30px;
                height: 30px;
            }

            &:hover,
            &:focus {
                text-decoration: none;
                outline: 0;
                box-shadow: none;
            }
        }

        .navbar-collapse {
            background-color: #fff !important;
            .navbar-nav {
                gap: 20px;

                .nav-link {
                    color: #4B4B4B;
                    font-weight: 600;
                    text-align: center;
                    position: relative;
                    display: inline-block;

                    &::after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: -5px;
                        transform: translateX(-50%);
                        width: 0;
                        height: 2px;
                        background-color: #481EAF;
                        transition: width 0.3s ease;
                    }

                    &:hover {
                        color: #481EAF;

                        &::after {
                            width: 80%;
                        }
                    }
                }
            }

            .btn-contact {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 10px 10px 20px;
                background-color: #4A22E6;
                color: #fff;
                font-weight: 600;
                border-radius: 50px;
                text-decoration: none;
                font-size: 16px;

                i {
                    margin-left: 15px;
                    background-color: #fff;
                    color: #4A22E6;
                    width: 25px;
                    height: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    font-size: 16px;
                }

                &:hover {
                    background-color: #3a1bb7;

                    i {
                        transform: translateX(5px);
                    }
                }
            }

            @media screen and (max-width: 991px) {
                position: absolute;
                top: 100%;
                background-color: #f8f9fa;
                width: calc(100% - 24px);
                border-bottom-left-radius: 50px;
                border-bottom-right-radius: 50px;
            }
        }
    }


    &.is-sticky {
        top: 0;

        .img-fluid {
            max-width: 80%;
            text-align: start;
        }
    }
}