.contact {
    margin: 50px 0;

    .contact-header {
        margin-bottom: 50px;

        .contact-title {
            text-indent: 25px;
            position: relative;
            color: #481EAF;
            text-transform: uppercase;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: max-content;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 8px;
                height: 8px;
                background-color: #481EAF;
                border-radius: 50%;
                transform: translateY(-50%);
            }
        }

        .contact-subtitle {
            font-size: 30px;
            color: #0E2045;
            font-weight: 600;
            margin: 0;
        }
    }

    .contact-form {
        margin: 0 auto;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: stretch; // Ensure children stretch to the same height
        gap: 50px;

        h2 {
            text-align: center;
            margin-bottom: 20px;
        }

        .contact-info {
            background: #481EAF;
            color: #fff;
            padding: 20px;
            border-radius: 10px;
            width: 40%;
            display: flex;
            flex-direction: column;
            position: relative;

            .contact-details {
                text-align: start;

                h4 {
                    font-weight: 600;
                    font-size: 24px;
                }

                p {
                    font-size: 14px;
                }
            }

            hr {
                border: none;
                border-top: 1px solid transparent;
                border-image: repeating-linear-gradient(to right, #5127B8, #fff, #5127B8);
                border-image-slice: 1;
            }

            .conetcinfo {
                a {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    color: #fff;
                    text-decoration: none;
                    font-size: 14px;
                    margin-bottom: 10px;

                    .iconfont {
                        position: relative;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        height: 30px;
                        border-radius: 5px;
                        cursor: pointer;
                        overflow: hidden;
                        transition: background-color 0.3s ease;
                        background-image: linear-gradient(to bottom, #5127B8, #481EAF);
                        text-decoration: none;
                        z-index: 2;

                        &::before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            transition: background-color 0.3s ease;
                            border-radius: 5px;
                            z-index: 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    &:hover {
                        .iconfont {
                            background-color: #1A2A4A;

                            &::before {
                                background-color: #5127B8;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                        align-items: flex-start;
                    }

                    span {
                        width: calc(100% - 45px);
                        text-align: start;
                    }
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100px;
                height: 100px;
                background-image: url(../../../Assets/Images/Home/getintouch.png);
                background-size: cover;
                background-repeat: no-repeat;
            }


            @media screen and (min-width:0px) and (max-width:1199px) {
                width: 100%;
                margin-bottom: 15px;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            width: 60%;
            justify-content: space-between;
            gap: 50px;

            .contact-input {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
                gap: 15px;
                justify-items: center;

                .input-form {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    width: 100%;
                    gap: 5px;

                    label {
                        text-align: start;
                        color: #0E2045;
                        font-weight: 600;
                        font-size: 16px;
                    }

                    input,
                    textarea {
                        width: 100%;
                        padding: 10px;
                        border: 1px solid rgba(75, 75, 75, 0.05);
                        border-radius: 10px;
                        background-color: rgba(75, 75, 75, 0.05);
                        font-size: 14px;
                        outline: none;
                    }

                    &:last-child {
                        grid-column: span 2;
                    }
                }

                @media screen and (min-width:0px) and (max-width:575px) {
                    display: flex;
                    flex-direction: column;
                }

                @media screen and (min-width:576px) and (max-width:1199px) {
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                }
            }

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 10px 10px 20px;
                background-color: #4A22E6;
                color: #fff;
                font-weight: 600;
                border-radius: 50px;
                text-decoration: none;
                transition: background-color 0.3s ease, transform 0.3s ease;
                position: relative;
                overflow: hidden;
                font-size: 16px;
                outline: none;
                border: none;
                width: max-content;

                i {
                    margin-left: 15px;
                    background-color: #fff;
                    color: #4A22E6;
                    width: 25px;
                    height: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    font-size: 16px;
                    transition: transform 0.3s ease;
                }

                &:hover {
                    background-color: #4A22E6;

                    i {
                        transform: translateX(5px);
                    }
                }
            }

            @media screen and (min-width:0px) and (max-width:1199px) {
                width: 100%;
                margin-bottom: 15px;
            }
        }

        @media screen and (min-width:0px) and (max-width:1199px) {
            display: block;
        }
    }
}