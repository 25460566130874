// src/components/Counter.scss
.count {
    background-color: #481EAF;
    padding: 50px 0px;
    position: relative;

    .counter-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 15px;
        color: #fff;
        width: 100%;
        height: 100%;
        position: relative;
        gap: 15px;

        .icon {
            position: relative;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            border-radius: 5px;
            cursor: pointer;
            overflow: hidden;
            transition: background-color 0.3s ease;
            background-image: linear-gradient(to bottom, #7046D7, #481EAF);
            text-decoration: none;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #7046D7;
                opacity: 0;
                transition: opacity 0.3s ease;
                border-radius: 5px;
                z-index: 1;
            }

            &:hover::before {
                opacity: 1;
            }

            .iconfont {
                font-size: 30px;
                position: relative;
                z-index: 2;
                color: #fff;
            }
        }

        .contain {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            .counter {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 0;
            }

            .title {
                font-size: 14px;
            }

            .subtitle {
                font-size: 0.9rem;
            }
        }

        &::after {
            content: '';
            background-color: #5A30C1;
            width: 2px;
            height: 100%;
            margin-left: auto;

            @media screen and (min-width:0px) and (max-width:1199px) {
                display: none;
            }
        }


    }

    .container {
        .row {
            &>* {
                &:last-child {
                    .counter-container {
                        &::after {
                            width: 0px;
                        }
                    }
                }
            }
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 10%;
        left: 1%;
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        background-image: url(../../../Assets/Images/Home/counter.svg);
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 10%;
        right: 1%;
        width: 50px;
        height: 50px;
        background-repeat: no-repeat;
        background-image: url(../../../Assets/Images/Home/counter.svg);
        transform: rotate(180deg);
    }
}