// Footer.scss

#footer {
    background-image: url(../../Assets/Images/Footer/footer.svg);
    background-repeat: no-repeat;
    padding: 50px 0px 0px;
    background-size: cover;

    h5 {
        font-size: 18px;
        color: #fff;
        text-align: start;
        margin-bottom: 5px;
        font-weight: 600;

        @media screen and (min-width:0px) and (max-width:767px) {
            text-align: center;
        }
    }

    p {
        font-size: 14px;
        color: #fff;
        text-align: start;
        margin-bottom: 0;
        font-weight: 400;

        @media screen and (min-width:0px) and (max-width:767px) {
            text-align: center;
        }
    }

    .input-group {
        position: relative;
        width: 70%;
        margin-left: auto;

        .form-control {
            padding: 15px 20px;
            border-radius: 50px;
            background-color: #5127B8;
            color: #fff;
            border: none;
            font-size: 14px;

            &::placeholder {
                color: #fff;
                font-size: 14px;
            }

            &:focus {
                box-shadow: none;
            }
        }

        button {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            border-radius: 50px;
            padding: 5px 20px;
            color: #481EAF;
            background-color: #fff;
            z-index: 1;
            font-size: 14px;
            font-weight: 600;
            border: none;
            width: 110px;
            border: 1px solid transparent;
            transition: background-color 0.3s ease;

            &:hover,
            &:focus {
                box-shadow: none;
                border: 1px solid #fff;
                background-color: transparent;
                color: #fff;
            }
        }

        @media screen and (min-width:768px) and (max-width:991px) {
            width: 100%;
        }

        @media screen and (min-width:0px) and (max-width:767px) {
            margin-right: auto;
        }

        @media screen and (min-width:0px) and (max-width:575px) {
            margin-right: auto;
            width: 100%;
        }
    }

    hr {
        border: none;
        border-top: 1px solid transparent;
        border-image: repeating-linear-gradient(to right, #5127B8, #fff, #5127B8);
        border-image-slice: 1;
    }

    .leftside {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 20px;

        .logo {
            cursor: pointer;
        }

        .socialicon {
            padding: 10px 0px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;

            a {
                position: relative;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                border-radius: 5px;
                cursor: pointer;
                overflow: hidden;
                transition: background-color 0.3s ease;
                background-image: linear-gradient(to bottom, #5127B8, #481EAF);
                text-decoration: none;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #5127B8;
                    opacity: 0;
                    transition: opacity 0.3s ease;
                    border-radius: 5px;
                    z-index: 1;
                }

                &:hover::before {
                    opacity: 1;
                }


            }

            .iconfont {
                font-size: 16px;
                position: relative;
                z-index: 2;
            }
        }

        p {
            font-size: 14px;
            color: #fff;
            text-align: start;
            margin-bottom: 0;
            font-weight: 400;

            @media screen and (min-width:0px) and (max-width:767px) {
                text-align: start;
            }
        }
    }

    h4 {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        text-align: start;
        position: relative;
        text-indent: 24px;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 8px;
            height: 8px;
            transform: translateY(-50%);
            border-radius: 50%;
            background-color: #fff;
        }
    }

    ul {
        padding: 0;

        li {
            margin: 0;
            text-indent: 24px;
            list-style: none;
            text-align: start;
            color: #fff;
            font-size: 14px;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0px;
            }

            a {
                color: #fff;
                text-decoration: none;
                outline: none;
            }
        }
    }

    .conetcinfo {
        a {
            display: flex;
            align-items: center;
            gap: 15px;
            color: #fff;
            text-decoration: none;
            font-size: 14px;
            margin-bottom: 10px;

            .iconfont {
                position: relative;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                border-radius: 5px;
                cursor: pointer;
                overflow: hidden;
                transition: background-color 0.3s ease;
                background-image: linear-gradient(to bottom, #5127B8, #481EAF);
                text-decoration: none;
                z-index: 2;

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transition: background-color 0.3s ease;
                    border-radius: 5px;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &:last-child {
                margin-bottom: 0;
                align-items: flex-start;
            }

            span {
                width: calc(100% - 45px);
                text-align: start;
            }

            &:hover {
                .iconfont {
                    background-color: #1A2A4A;

                    &::before {
                        background-color: #5127B8;

                    }
                }
            }
        }
    }

    span {
        font-size: 14px;
        text-align: start;
        color: #fff;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media screen and (min-width:0px) and (max-width:767px) {
            justify-content: center;
        }
    }

    .otherpage {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 15px;

        a {
            color: #fff;
            text-decoration: none;
            font-size: 14px;
            transition: 0.3s ease all;
            display: block;
            cursor: pointer;

            // &:hover {
            //     text-decoration: underline;
            // }

        }


        @media screen and (min-width:0px) and (max-width:767px) {
            justify-content: center;
            display: block;
        }
    }
}

