.service-section {
    display: flex;
    justify-content: space-between;
    margin: 50px 0px;
    position: relative;

    .service-intro {
        text-align: start;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 15px;

        .service-label {
            text-indent: 25px;
            position: relative;
            color: #481EAF;
            text-transform: uppercase;
            font-size: 18px;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 8px;
                height: 8px;
                background-color: #481EAF;
                border-radius: 50%;
                transform: translateY(-50%);
            }
        }

        .service-title {
            font-size: 30px;
            color: #0E2045;
            font-weight: 600;
            margin: 0;
        }

        .service-description {
            color: #4B4B4B;
            font-size: 14px;
            margin: 0;
        }

        .service-button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 10px 10px 20px;
            background-color: #4A22E6;
            color: #fff;
            font-weight: 600;
            border-radius: 50px;
            text-decoration: none;
            transition: background-color 0.3s ease, transform 0.3s ease;
            position: relative;
            overflow: hidden;
            font-size: 16px;
            outline: none;
            border: none;

            i {
                margin-left: 15px;
                background-color: #fff;
                color: #4A22E6;
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-size: 16px;
                transition: transform 0.3s ease;
            }

            &:hover {
                background-color: #4A22E6;

                i {
                    transform: translateX(5px);
                }
            }
        }
    }

    .service-cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 30px;
        justify-items: center;

        .service-card {
            background-color: white;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 15px;
            cursor: pointer;

            &:nth-child(1) {
                .icon-wrapper {
                    position: relative;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    border-radius: 5px;
                    cursor: pointer;
                    overflow: hidden;
                    transition: background-color 0.3s ease;
                    background-image: linear-gradient(to bottom, #FFF0E2, #fff);
                    text-decoration: none;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #FFF0E2;
                        opacity: 0;
                        transition: opacity 0.3s ease;
                        border-radius: 5px;
                        z-index: 1;
                    }

                    &:hover::before {
                        opacity: 1;
                    }

                    .iconfont {
                        font-size: 30px;
                        position: relative;
                        z-index: 2;
                        color: #CE8441;
                    }
                }
            }

            &:nth-child(2) {
                // grid-row: span 2;
                height: min-content;
                margin-top: auto;
                margin-bottom: auto;
                position: relative;
                top: 30px;

                .icon-wrapper {
                    position: relative;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    border-radius: 5px;
                    cursor: pointer;
                    overflow: hidden;
                    transition: background-color 0.3s ease;
                    background-image: linear-gradient(to bottom, #E2FFF8, #fff);
                    text-decoration: none;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #E2FFF8;
                        opacity: 0;
                        transition: opacity 0.3s ease;
                        border-radius: 5px;
                        z-index: 1;
                    }

                    &:hover::before {
                        opacity: 1;
                    }

                    .iconfont {
                        font-size: 30px;
                        position: relative;
                        z-index: 2;
                        color: #0D9A78;
                    }
                }

                @media screen and (min-width:0px) and (max-width:1199px) {
                    top: auto;
                }
            }

            &:nth-child(3) {
                .icon-wrapper {
                    position: relative;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    border-radius: 5px;
                    cursor: pointer;
                    overflow: hidden;
                    transition: background-color 0.3s ease;
                    background-image: linear-gradient(to bottom, #CFFFFF, #fff);
                    text-decoration: none;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #CFFFFF;
                        opacity: 0;
                        transition: opacity 0.3s ease;
                        border-radius: 5px;
                        z-index: 1;
                    }

                    &:hover::before {
                        opacity: 1;
                    }

                    .iconfont {
                        font-size: 30px;
                        position: relative;
                        z-index: 2;
                        color: #17858B;
                    }
                }
            }

            &:nth-child(4) {
                grid-row: span 2;
                height: min-content;
                margin-top: auto;

                // margin-bottom: auto;
                .Server-Provider {}


                .icon-wrapper {
                    position: relative;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    border-radius: 5px;
                    cursor: pointer;
                    overflow: hidden;
                    transition: background-color 0.3s ease;
                    background-image: linear-gradient(to bottom, #E2FFF8, #fff);
                    text-decoration: none;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #E2FFF8;
                        opacity: 0;
                        transition: opacity 0.3s ease;
                        border-radius: 5px;
                        z-index: 1;
                    }

                    &:hover::before {
                        opacity: 1;
                    }

                    .iconfont {
                        font-size: 30px;
                        position: relative;
                        z-index: 2;
                        color: #16675c;
                    }
                }
            }

            &:hover {
                &:nth-child(1) {
                    .icon-wrapper {
                        &::before {
                            opacity: 1;
                        }
                    }
                }

                &:nth-child(2) {
                    .icon-wrapper {
                        &::before {
                            opacity: 1;
                        }
                    }
                }

                &:nth-child(3) {
                    .icon-wrapper {
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }

            .card-title {
                font-weight: 600;
                color: #0E2045;
                margin: 0;
                font-size: 20px;
            }

            .card-description {
                color: #4B4B4B;
                font-size: 14px;
                margin: 0;
                text-align: start;
            }
        }
    }

    .serviceshape {
        position: absolute;
        bottom: 20%;
        left: 5%;
        z-index: -1;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background: linear-gradient(45deg, rgba(147, 108, 247, 0.06), rgba(112, 59, 245, 4%));
        width: 52%;
        height: 70%;
        z-index: -1;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }
}