@font-face {
  font-family: "Poppins", sans-serif !important;
  src: url(Assets/Fonts/Poppins-Regular.ttf);
}



body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  box-sizing: border-box;
}

* {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  box-sizing: border-box;
  padding: 0;
}

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}