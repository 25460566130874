@font-face {
    font-family: "IconFont";
    src: url('IconFont.woff2?t=1716816031675') format('woff2'),
        url('IconFont.woff?t=1716816031675') format('woff'),
        url('IconFont.ttf?t=1716816031675') format('truetype');
}

.iconfont {
    font-family: "IconFont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before { content: "\e900"; }
.icon-call:before { content: "\e901"; }
.icon-facebook:before { content: "\e902"; }
.icon-instagram:before { content: "\e903"; }
.icon-twitter:before { content: "\e904"; }
.icon-linkedin:before { content: "\e905"; }
.icon-arrow-left:before { content: "\e906"; }
.icon-goal:before { content: "\e907"; }
.icon-eye:before { content: "\e908"; }
.icon-play:before { content: "\e909"; }
.icon-app-setting:before { content: "\e90a"; }
.icon-development:before { content: "\e90b"; }
.icon-medal:before { content: "\e90c"; }
.icon-hand:before { content: "\e90d"; }
.icon-ideas:before { content: "\e90e"; }
.icon-setting:before { content: "\e90f"; }
.icon-location:before { content: "\e910"; }
.icon-calendar:before { content: "\e911"; }
.icon-hand-report:before { content: "\e912"; }
.icon-users:before { content: "\e913"; }
.icon-web-code:before { content: "\e914"; }
.icon-server-provider:before { content: "\e915"; }


/**
 * Make by Icon Font Exporter
 * https://www.figma.com/community/plugin/1129455674275940478/Icon-Font-Exporter
 */
