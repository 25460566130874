.mainSlider {
    background: linear-gradient(45deg, rgba(147, 108, 247, 6%), rgba(112, 59, 245, 6%));
    position: relative;

    .trapezoid-image {
        background-size: cover;
        clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
        background-image: url(../../../Assets/Images/Home/banner.jpg);
        background-position: right;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        width: 40%;
        height: 100%;

        @media screen and (min-width:0px) and (max-width:575px) {
            display: none;
        }
    }

    .carousel {

        &:hover {

            .carousel-control-next,
            .carousel-control-prev {
                visibility: visible;
                width: 75px;
                height: 75px;
            }
        }

        .carousel-control-next {
            right: 5%;

            .carousel-control-prev-icon {

                background-image: url(../../../Assets/Images/Home/rightArrow.svg);
            }
        }

        .carousel-control-prev {
            left: 5%;

            .carousel-control-prev-icon {
                background-image: url(../../../Assets/Images/Home/leftArrow.svg);
            }
        }


        .carousel-control-next,
        .carousel-control-prev {
            background-color: transparent;
            color: #0E2045;
            top: calc(50% + 44px);
            width: 0;
            height: 0;
            border-radius: 50%;
            transform: translateY(-50%);
            margin: 0px auto;
            visibility: hidden;
            transition: 0.3s ease all;

            span {
                fill: #0E2045;
            }
        }

        .carousel-indicators {
            display: none;
        }

        .carousel-inner {
            .carousel-item {
                .banner {
                    padding: 50px 0px;
                    text-align: start;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    gap: 15px;

                    .companyname {
                        text-indent: 25px;
                        position: relative;
                        color: #481EAF;
                        text-transform: uppercase;
                        font-size: 18px;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 0;
                            width: 8px;
                            height: 8px;
                            background-color: #481EAF;
                            border-radius: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    h1 {
                        font-size: 50px;
                        color: #0E2045;
                        font-weight: 600;

                        span {
                            color: #481EAF;
                        }
                    }

                    p {
                        color: #4B4B4B;
                        font-size: 14px;
                    }

                    .bnnerinfo {
                        display: flex;
                        align-items: center;
                        gap: 30px;

                        .trusted-partners {
                            display: flex;
                            align-items: center;
                            gap: 15px;

                            .partners {
                                display: flex;

                                .partner-img {
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 50%;
                                    overflow: hidden;
                                    flex-shrink: 0;
                                    margin-left: -20px;
                                    transition: 0.3s ease all;
                                    cursor: pointer;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }

                                    &:first-child {
                                        margin-left: 0px;
                                    }

                                    &:last-child {
                                        background-color: #0E2045;
                                        color: white;
                                        border-radius: 50%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        .count-inner {
                                            font-size: 14px;
                                            font-weight: 600;
                                        }
                                    }

                                    &:hover {
                                        position: relative;
                                        z-index: 1;
                                    }
                                }
                            }

                            .info {
                                display: flex;
                                align-items: center;

                                .text {
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #0E2045;
                                }
                            }
                        }

                        .btn-Lets {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 10px 10px 10px 20px;
                            background-color: #4A22E6;
                            color: #fff;
                            font-weight: 600;
                            border-radius: 50px;
                            text-decoration: none;
                            transition: background-color 0.3s ease, transform 0.3s ease;
                            position: relative;
                            overflow: hidden;
                            font-size: 16px;
                            outline: none;
                            border: none;

                            i {
                                margin-left: 15px;
                                background-color: #fff;
                                color: #4A22E6;
                                width: 25px;
                                height: 25px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 50%;
                                font-size: 16px;
                                transition: transform 0.3s ease;
                            }

                            &:hover {
                                background-color: #4A22E6;

                                i {
                                    transform: translateX(5px);
                                }
                            }
                        }

                        @media screen and (min-width:0px) and (max-width:575px) {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }
                }
            }
        }
    }

    .bannershape {
        position: absolute;
        bottom: 20%;
        left: 5%;
    }

    .company-logo {
        width: 100%;
        overflow: hidden;
        position: relative;
        margin-top: 50px;
        z-index: -2;

        .marquee-content {
            display: flex;
            width: 100%;
            gap: 40px;
        }

        .marquee {
            display: flex;
            width: calc(200%);
            animation: scroll 20s linear infinite;

            .marquee-content {


                .logo {
                    width: auto;
                    text-wrap: nowrap;
                    font-size: 30px;
                    color: #0E2045;
                    opacity: 0.05;
                    display: flex;
                    align-items: center;
                    gap: 50px;
                    text-transform: uppercase;

                    span {
                        width: 30px;
                        height: 30px;
                        background-color: transparent;
                        border: 2.5px solid #0E2045;
                        display: block;
                        border-radius: 50%;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 15px;
                            height: 15px;
                            background-color: #0E2045;
                            opacity: 0.5;
                            border-radius: 50%;
                        }
                    }
                }

            }

            @keyframes scroll {
                0% {
                    transform: translateX(0);
                }

                100% {
                    transform: translateX(-50%);
                }
            }
        }

    }
}