.step {
    margin: 50px 0px;
    background-image: linear-gradient(45deg, rgba(147, 108, 247, 0.06), rgb(113, 59, 245, 0.04));
    padding: 50px 0px;
    gap: 50px 0;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: rotate(180deg);
        margin-right: auto;
        background-repeat: no-repeat;
        background-image: url(../../../Assets/Images/Home/stap.svg);
        background-position-x: right;
        background-position-y: top;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        margin-right: auto;
        background-repeat: no-repeat;
        background-image: url(../../../Assets/Images/Home/stap.svg);
        background-position-x: right;
        background-position-y: top;
    }

    .container {
        position: relative;

        .row {

            &:first-child {

                @media screen and (min-width:0px) and (max-width:991px) {
                    margin-bottom: 30px;
                }
            }

            &:last-child {
                background-repeat: no-repeat;
                background-image: url(../../../Assets/Images/Home/stapvector.svg);
                background-position: center;

                @media screen and (min-width:0px) and (max-width:991px) {
                    background-image: none;
                }

                &>* {
                    &:first-child {
                        .stepinner {
                            margin-top: 90%;

                            @media screen and (min-width:1200px) and (max-width:1399px) {
                                margin-top: 120%;
                            }

                            @media screen and (min-width:992px) and (max-width:1199px) {
                                margin-top: 135%;
                            }

                            @media screen and (min-width:0px) and (max-width:991px) {
                                margin-top: 0px;
                            }

                        }
                    }

                    &:nth-child(2) {
                        .stepinner {
                            margin-top: 25%;

                            .step-icon {
                                order: 3;
                            }

                            @media screen and (min-width:1200px) and (max-width:1399px) {
                                margin-top: 40%;

                                .step-icon {
                                    order: 3;
                                }
                            }

                            @media screen and (min-width:992px) and (max-width:1199px) {
                                margin-top: 25%;

                                .step-icon {
                                    order: 3;
                                }
                            }

                            @media screen and (min-width:0px) and (max-width:991px) {
                                margin-top: 0px;

                                .step-icon {
                                    order: 0;
                                }
                            }

                        }
                    }

                    &:nth-child(3) {
                        .stepinner {
                            margin-top: 65%;

                            @media screen and (min-width:1200px) and (max-width:1399px) {
                                margin-top: 85%;

                            }

                            @media screen and (min-width:992px) and (max-width:1199px) {
                                margin-top: 95%;
                            }

                            @media screen and (min-width:0px) and (max-width:991px) {
                                margin-top: 0px;
                            }
                        }
                    }

                    &:nth-child(4) {
                        .stepinner {
                            margin-top: 30%;

                            .step-icon {
                                order: 3;
                            }

                            @media screen and (min-width:1200px) and (max-width:1399px) {
                                margin-top: 60%;

                                .step-icon {
                                    order: 3;
                                }
                            }

                            @media screen and (min-width:992px) and (max-width:1199px) {
                                margin-top: 60%;

                                .step-icon {
                                    order: 3;
                                }
                            }

                            @media screen and (min-width:0px) and (max-width:991px) {
                                margin-top: 0px;

                                .step-icon {
                                    order: 0;
                                }
                            }
                        }
                    }
                }
            }

            &>* {
                &:first-child {
                    gap: 15px;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    .steps-heading {
        text-indent: 25px;
        position: relative;
        color: #481EAF;
        text-transform: uppercase;
        font-size: 18px;
        width: max-content;
        margin: 0 auto;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 8px;
            height: 8px;
            background-color: #481EAF;
            border-radius: 50%;
            transform: translateY(-50%);
        }
    }

    .steps-subheading {
        font-size: 30px;
        color: #0E2045;
        font-weight: 600;
        margin: 0;
    }

    .stepinner {
        gap: 15px;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .step-icon {
            color: #fff;
            cursor: pointer;
            overflow: hidden;
            background-image: linear-gradient(to bottom, #7046D7, #481EAF);
            text-decoration: none;
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 20px;
            border-radius: 50%;
            line-height: 0;
            font-size: 50px;
            position: relative;
            transition: .3s ease-in-out;
            z-index: 1;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #7046D7;
                opacity: 0;
                transition: opacity 0.3s ease;
                border-radius: 5px;
                z-index: 1;
            }

            &:hover::before {
                opacity: 1;
            }

            .iconfont {
                font-size: 40px;
                position: relative;
                z-index: 2;
                color: #fff;
            }
        }

        .step-title {
            font-size: 20px;
            font-weight: 600;
            color: #0E2045;
        }

        .step-description {
            color: #4B4B4B;
            font-size: 14px;
            margin: 0;
        }
    }
}