.blog-container {
  padding: 50px 0;
  background: linear-gradient(45deg, rgb(148, 108, 247, 0.06), rgb(113, 59, 245, 0.04));
  position: relative;

  .blog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    .title {
      .blog-title {
        text-indent: 25px;
        position: relative;
        color: #481EAF;
        text-transform: uppercase;
        font-size: 18px;
        text-align: start;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 8px;
          height: 8px;
          background-color: #481EAF;
          border-radius: 50%;
          transform: translateY(-50%);
        }
      }

      .blog-subtitle {
        font-size: 30px;
        color: #0E2045;
        font-weight: 600;
        margin: 0;
        text-align: start;
      }
    }

    .blog-more {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 10px 10px 20px;
      background-color: #4A22E6;
      color: #fff;
      font-weight: 600;
      border-radius: 50px;
      text-decoration: none;
      transition: background-color 0.3s ease, transform 0.3s ease;
      position: relative;
      overflow: hidden;
      font-size: 16px;
      outline: none;
      border: none;
      text-wrap: nowrap;

      i {
        margin-left: 15px;
        background-color: #fff;
        color: #4A22E6;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 16px;
        transition: transform 0.3s ease;
      }

      &:hover {
        background-color: #4A22E6;

        i {
          transform: translateX(5px);
        }
      }
    }
  }

  .card {
    border-radius: 20px;
    color: #000;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
    border: 0px;
    height: 100%;

    .card-img-top {
      object-fit: cover;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    .card-body {
      padding: 15px;

      .card-title {
        font-size: 20px;
        font-weight: 600;
        color: #0E2045;
      }

      .card-text {
        color: #4B4B4B;
        font-size: 14px;
        margin: 0;
        text-align: start;
      }

    }

    .card-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid rgba(75, 75, 75, 0.10);
      background-color: #fff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

      .read-more {
        color: #4B4B4B;
        text-decoration: none;
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;


        .iconfont {
          font-size: 12px;
          background-color: #4B4B4B;
          height: 20px;
          width: 20px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
      }

      .blog-date {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 0.9rem;
        color: #4B4B4B;

        .iconfont {
          color: #481EAF;
        }
      }
    }

    &:hover {
      transform: translateY(-5px);

      .card-footer {
        .read-more {
          color: #481EAF;

          .iconfont {
            background-color: #481EAF;
          }
        }
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 10%;
    left: 1%;
    width: calc(100% - 1%);
    height: 100%;
    background-repeat: no-repeat;
    background-image: url(../../../Assets/Images/Home/bloginner.png);
    z-index: -1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 10%;
    right: 1%;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-image: url(../../../Assets/Images/Home/counter.svg);
    transform: rotate(180deg);
    opacity: 0.2;
    z-index: -1;

  }
}