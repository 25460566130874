#aboutus {
    background-image: url(../../../Assets/Images/Home/aboutus.svg);
    background-size: cover;
    background-repeat: no-repeat;
    margin: 50px 0px 140px;

    .aboutusImage {
        position: relative;

        .images {
            text-align: start;
            width: 100%;

            img {
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
                border-radius: 20px;
                max-width: 80%;
            }
        }

        .contain {
            display: flex;
            align-items: center;
            position: absolute;
            top: 55%;

            .images {
                position: relative;

                img {
                    border: 10px solid #fff;
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
                    max-width: 100%;
                }

                .icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    .iconfont {
                        background-color: #fff;
                        width: 50px;
                        height: 50px;
                        display: flex;
                        text-align: start;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        color: #481EAF;
                        position: relative;
                        font-size: 18px;

                        &::after {
                            content: '';
                            position: absolute;
                            width: calc(100% + 20px);
                            height: calc(100% + 20px);
                            background-color: #ffffff50;
                            border-radius: 50%;
                            z-index: -1;
                        }
                    }


                }
            }

            .text {
                background-color: #fff;
                padding: 20px 40px;
                border-radius: 15px;
                margin-left: 15px;
                margin-top: 60px;
                font-size: 18px;
                font-weight: 600;
                box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
                text-wrap: nowrap;
                color: #0E2045;


                @media screen and (min-width: 0px) and (max-width: 575px) {
                    padding: 10px 15px;
                }

                @media screen and (min-width: 576px) and (max-width: 767px) {
                    padding: 15px 20px;
                }

                @media screen and (min-width: 992px) and (max-width: 1199px) {
                    padding: 10px 15px;
                }

                @media screen and (min-width: 1200px) and (max-width: 1399px) {
                    padding: 15px 20px;
                }
            }


        }
    }

    .aboutus {
        text-align: start;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 15px;

        .aboutusname {
            text-indent: 25px;
            position: relative;
            color: #481EAF;
            text-transform: uppercase;
            font-size: 18px;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 8px;
                height: 8px;
                background-color: #481EAF;
                border-radius: 50%;
                transform: translateY(-50%);
            }
        }

        h3 {
            font-size: 30px;
            color: #0E2045;
            font-weight: 600;
            margin: 0;
        }

        p {
            color: #4B4B4B;
            font-size: 14px;
            margin: 0;
        }

        .our {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .innerour {
                display: flex;
                align-items: flex-start;
                gap: 10px;

                .icon {
                    position: relative;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    border-radius: 5px;
                    cursor: pointer;
                    overflow: hidden;
                    transition: background-color 0.3s ease;
                    background-image: linear-gradient(to bottom, #E3DBFF, #fff);
                    stroke: solid linear-gradient(to top, #E3DBFF, #fff);
                    text-decoration: none;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #E3DBFF;
                        opacity: 0;
                        transition: opacity 0.3s ease;
                        border-radius: 5px;
                        z-index: 1;
                    }

                    &:hover::before {
                        opacity: 1;
                    }

                    .iconfont {
                        font-size: 20px;
                        position: relative;
                        z-index: 2;
                        color: #481EAF;
                    }
                }

                .content {
                    display: flex;
                    gap: 5px;
                    flex-direction: column;
                    width: calc(100% - 50px);

                    h6 {
                        font-weight: 600;
                        color: #481EAF;
                        margin: 0;
                    }

                    p {
                        margin: 0;
                    }
                }
            }
        }

        .btn-learnmore {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 10px 10px 20px;
            background-color: #4A22E6;
            color: #fff;
            font-weight: 600;
            border-radius: 50px;
            text-decoration: none;
            transition: background-color 0.3s ease, transform 0.3s ease;
            position: relative;
            overflow: hidden;
            font-size: 16px;
            outline: none;
            border: none;

            i {
                margin-left: 15px;
                background-color: #fff;
                color: #4A22E6;
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-size: 16px;
                transition: transform 0.3s ease;
            }

            &:hover {
                background-color: #4A22E6;

                i {
                    transform: translateX(5px);
                }
            }
        }
    }

    @media screen and (min-width:0px) and (max-width:991px) {
        margin: 50px 0px 50px;
    }
}