#TermOfUse {
    text-align: initial;

    .term-title {
        text-align: center;
        margin-bottom: 40px;
    }

    h1,
    h2 {
        color: #333;
    }

    p {
        margin-bottom: 10px;
        margin-left: 28px;
    }

    ul {
        padding: 0;
    }

    li {
        margin-bottom: 5px;
        margin-left: 72px;

    }
}